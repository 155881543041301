<script>
import Detail from './detail'
import DatePicker from "@vuepic/vue-datepicker"

export default {
  props: {

  },
  components: {
    Detail,
    DatePicker
  },
  data() {
    return {
      search: {
        userType: 'admin',
        beginDate: '',
        endDate: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지

      vendors: [],
      games: []
    }
  },
  created() {

  },
  mounted() {

    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(0)
    beginDate.setMinutes(0)
    beginDate.setSeconds(0)

    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.getVendors().then()
    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      // this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {
      const loader = this.$loading.show()
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: { userType: this.search.userType },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText
        const result = await this.$API.transaction.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(let key in result.documents) {
          let doc = result.documents[key]
          doc.showDetail = false
          this.list.push(doc)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openDetail: function(data) {
      this.$refs['modal-detail'].open(data)
    },

    async getVendors() {
      const result = await this.$API.setting.vendors({})
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.vendors = result.documents
    },

    async getGames() {
      if (!this.search.vendor) return
      const result = await this.$API.setting.games({ search: { vendor: this.search.vendor }, sort: { sort: 1 }, limit: 1000 })
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.games = result.documents
    }
  },
  watch: {
    page: function() {
      this.searchList().then()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <form class="header-search" @submit.prevent="searchList">
      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.beginDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('시작일')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.endDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('종료일')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="limit">
            <option :value="10">10개</option>
            <option :value="25">25개</option>
            <option :value="50">50개</option>
            <option :value="100">100개</option>
          </select>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="input-group mb-2">
              <button type="button" class="btn btn-outline-success" @click="goFirst">처음</button>
              <button type="button" class="btn btn-outline-primary" @click="goPre">이전</button>
              <input type="number" class="form-control" min="1" v-model="page" />
              <button type="button" class="btn btn-outline-info" @click="goNext">다음</button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <!-- 리스트 //-->
    <div id="table-wrapper" class="table-responsive mb-0 rounded-3" style="overflow: auto;">
      <table class="table align-middle table-sm table-hover table-nowrap sticky-table table-striped-columns">
        <thead class="table-dark">
        <tr>
          <th>
            <a href="javascript:void(0)" @click="setSort('createdAt')">비고</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
          <th>{{ $t('형식') }}</th>
          <th>{{ $t('대상') }}</th>
          <th>{{ $t('이전')}}</th>
          <th>
            <a href="javascript:void(0)" @click="setSort('cash')">{{ $t('금액') }}</a>&nbsp;
            <template v-if="sort.cash"><span class="fa fa-sort-amount-down" v-if="sort.cash === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.cash === 1"></span></template>
          </th>
          <th>{{ $t('이후')}}</th>
          <th>
            <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
            <template v-if="sort.createdAt"><span class="fa fa-sort-amount-down" v-if="sort.createdAt === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.createdAt === 1"></span></template>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in list" :key="record.id">
          <!-- 번호 //-->
          <td>
            <button type="button" class="btn btn-secondary btn-sm" @click="openDetail(record)"><span class="mdi mdi-menu"></span></button>
          </td>
          <!-- 회원정보 //-->

          <!-- 거래 형식 //-->

          <!-- 거래 방식 //-->
          <td>
            <!--
            method: game, category: money, type: send or receive => 사이트<->케이곤 머니이동
            method: cash, category: cash(파트너) or money(회원), type: send or receive => 회원간 캐쉬 이동(입/출금)
            method: game, category: turn, type: bet or win or draw or cancel => 게임 플레이
            //-->
            <span class="badge badge-pill badge-soft-primary p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'bet'">
              <i class="fa fa-book" v-if="record.message"></i>
              배팅
            </span>
            <span class="badge badge-pill badge-soft-success p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'win'">
              <i class="fa fa-book" v-if="record.message"></i>
              승
            </span>
            <span class="badge badge-pill badge-soft-danger p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'lose'">
              <i class="fa fa-book" v-if="record.message"></i>
              패
            </span>
            <span class="badge badge-pill badge-soft-info p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'draw'">
              <i class="fa fa-book" v-if="record.message"></i>
              무
            </span>
            <span class="badge badge-pill badge-soft-secondary p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'cancel'">
              <i class="fa fa-book" v-if="record.message"></i>
              취소
            </span>
            <span class="badge badge-pill badge-soft-primary p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'receive'">
              <i class="fa fa-plus" v-if="record.message"></i>
              입금
            </span>
            <span class="badge badge-pill badge-soft-danger p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'send'">
              <i class="fa fa-minus" v-if="record.message"></i>
              출금
            </span>
            <span class="badge badge-pill badge-soft-primary p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'receive'">
              <i class="fa fa-plus" v-if="record.message"></i>
              입금
            </span>
            <span class="badge badge-pill badge-soft-danger p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'send'">
              <i class="fa fa-minus" v-if="record.message"></i>
              출금
            </span>
            <span class="badge p-1" :class="(record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter')) ? 'bg-danger':'bg-primary'" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'enter'">
              <i class="fa fa-book" v-if="record.message"></i>
              게임실행
            </span>
            <span class="badge badge-pill badge-soft-danger p-1" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'leave'">
              <i class="fa fa-book" v-if="record.message"></i>
              게임퇴장
            </span>
          </td>
          <td class="text-center">{{ record.sender.username}}</td>
          <!-- 이전 보유알 //-->
          <td class="text-end">{{ record.beforeAmount.floatVal(2).formatThousands() }}</td>
          <!-- 금액 //-->
          <td class="text-end" :class="{'text-danger' : (record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter'))}">{{ record.amount.floatVal(2).formatThousands() }}</td>
          <!-- 이후 보유알 //-->
          <td class="text-end">{{ record.afterAmount.floatVal(2).formatThousands() }}</td>
          <!-- 생성일 //-->
          <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>

    <Detail ref="modal-detail" />
  </div>
</template>
